.notFound {
    width: 100%;
    height: 100vh;
    background-color: #89CFF0;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h1, h2 {
        color: #fff;
        font-size: 180px;
        font-weight: bold;
        text-shadow: 3px 3px rgba(0,0,0,0.8);
    }

    h2 {
        margin-top: -10px;
        font-size: 41px;
    }

    .notFoundBtn {
        width: 310px;
        height: 45px;
        margin-top: 36px;
        font-size: 19px;
        font-weight: 600;
        border-radius: 12px;
        
        color: #000;
        border: 1px solid #000;
        background-color: #fff;

        display: flex;
        align-items: center;
        justify-content: center;

        cursor: pointer;
        transition: 0.5s;
    }

    .notFoundBtn:hover {
        color: #000;
        border: 1px solid #fff;
    }

}