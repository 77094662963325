.about {
    width: 75%;
    padding: 60px 20px;
    color: rgba(0, 0, 0, 0.8);
    
    p {
        font-size: 18px;
        line-height: 1.78;
        margin-top: 10px;
        word-spacing: 2px;
    }
}

@media only screen and (max-width: 600px) {
    .about {
        width: 80% !important;
        padding: 40px 0px !important;
    }

    .about p {
        margin-top: 12px;
        margin-bottom: 0;
    }

    #about {
        top: 550px !important;
    }
}