.homescreen {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    
    .homeWrapper {
        width: 100%;
        height: 620px;
        display: flex;
        align-items: center;
        justify-content: center;        
        background-color: #ADD8E6;

        .homeImage {
            width: 300px;
            height: 350px;
            margin-top: 70px;
            background-image: url('../images/home.png');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }

    }    
}

@media only screen and (max-width: 600px) {
    .homeImage {
        width: 250px !important;
        height: 300px !important;
    }
    
}
