.csr {
    width: 100%;
    margin-top: 70px;
    text-align: center;
    display: flex;
    flex-direction: column;

    .csrHead {
        font-size: 26px;
        font-weight: 500;
        text-align: center;
        margin: 50px 0 16px 0;

        p {
            font-size: 20px;
            margin-top: 4px;
            font-weight: 400;
        }
    }
    
    .csrWrapper {
        width: 80%;
        margin: 20px 0 75px 0;

        display: flex;
        align-self: center;
        justify-content: space-between;
        color: rgba(0, 0, 0, 0.8);
        
        .info {
            width: 49%;
            text-align: left;
            padding: 25px 30px 30px 30px;
            border: 2px solid rgba(0, 0, 0, 0.2);

            .head {
                font-size: 20px;
                font-weight: 500;
            }
            h5 {
                font-size: 18px;
                margin: 12px 0 20px 0;
            }
            p {
                font-size: 18px;
                line-height: 1.6;
                word-spacing: 2px;
                margin: 12px 0 0 0;
            }
        }

        .map {
            width: 49%;
            border: 2px solid  rgba(0, 0, 0, 0.2);
            
            .csrImg {
                width: 100%;
                height: 100%;
                background-image: url('../images/csr-1.jpg');
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .csrHead {
        width: 80%;
        align-self: center;
        margin-bottom: 0 !important;
    }

    .csrWrapper {
        flex-direction: column;
    }

    .csrWrapper .info {
        width: 100% !important;
        text-align: center !important;
    }

    .csrWrapper .map {
        margin-top: 40px;
        height: 300px;
        width: 100% !important;
    }
}