.bg-body-tertiary {
    font-size: 19px;
    border-bottom: 3px solid white;
    background-color: #89CFF0 !important;
}

.container {
    padding: 0 !important;
}

.navbar-brand {
    display: flex;
    color: #000;
    font-weight: 600;
    font-style: italic;
    align-items: center;
    
    img {
        width: 36px;
        height: 40px;
    }
}

.nav-link {
    color: #000;
    font-weight: 500;
}
    
.nav-custom-btn {
    height: 38px;
    width: 150px;
    margin-left: 6px;
    margin-top: 1px;
    
    transition: 0.5s;
    border-radius: 8px;    
    border: 2px solid #000000;
    
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
}

.nav-custom-btn:hover {
    color: #000 !important;
    background-color: #fff !important;
}

@media only screen and (max-width: 600px) {
    .container {
        padding: 12px !important;
    }
    .nav-custom-btn {
        margin-top: 8px !important;
        margin-left: 0px !important;
    }
}