.visionWrapper {
    width: 100%;
    height: 320px;
    padding: 0 10%;
    border-radius: 0;
    background-color: #89CFF0;

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    .vision {
        width: 300px;
        height: 140px;
        padding: 0 12px;
        
        display: flex;
        flex-direction: column;
        justify-content: center;
        
        color: rgba(0, 0, 0, 0.8);
        background-color: #ffffff;
        
        border-radius: 20px;
        box-shadow: 2px 2px rgba(0, 0, 0, 0.2);

        h5 {
            font-size: 20px;
        }
        p {
            font-size: 18px;
            line-height: 1.5;
            word-spacing: 1px;
            margin-bottom: -2px;
        }
    
    }
}

@media only screen and (max-width: 1200px) {
    .visionWrapper {
        height: auto !important;
        padding-top: 50px !important;
        padding-bottom: 50px !important;
        justify-content: center !important;
    }
    .visionWrapper .vision {
        width: 100% !important;
    }
    .visionWrapper .vision:nth-child(1), .vision:nth-child(2) {
        margin-bottom: 30px;
    }
}