.contact {
    width: 100%;
    margin-top: 70px;
    text-align: center;
    display: flex;
    flex-direction: column;

    .contactHead {
        font-size: 26px;
        font-weight: 500;
        text-align: center;
        margin: 50px 0 20px 0;
    }
    
    .contactWrapper {
        width: 75%;
        margin: 20px 0 75px 0;

        display: flex;
        align-self: center;
        justify-content: space-between;
        color: rgba(0, 0, 0, 0.8);
        
        .info {
            width: 49%;
            padding: 20px 30px;
            border: 2px solid rgba(0, 0, 0, 0.2);

            .head {
                font-size: 22px;
            }
            h4 {
                margin: 22px 0 20px 0;
            }
            p {
                font-size: 18px;
                line-height: 1.78;
                word-spacing: 2px;
                margin: 10px 0 0 0;
            }
        }

        .map {
            width: 49%;
            overflow: hidden;
            border: 2px solid  rgba(0, 0, 0, 0.2);

            iframe {
                width: 100%;
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .contactWrapper {
        width: 80% !important;
        flex-direction: column;
        margin-bottom: 60px !important;
    }
    .contactWrapper .info {
        width: 100% !important;
    }
    .contactWrapper .map {
        margin-top: 40px;
        width: 100% !important;
    }
}