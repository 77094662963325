.invest {
    width: 100%;
    margin-top: 70px;
    text-align: center;
    display: flex;
    flex-direction: column;
    
    .investWrapper {
        width: 75%;
        padding: 0 20px;
        align-self: center;
        margin: 50px 0 70px 0;
        color: rgba(0, 0, 0, 0.8);
        
        h4 {
            font-size: 26px;
            text-align: center;
            margin-bottom: 20px;
        }
        p {
            font-size: 18px;
            text-align: left;
            line-height: 1.78;
            word-spacing: 2px;
            margin: 12px 0 0 0;
        }

        .vdeck {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 40px;

            h4 {
                font-size: 20px;
                margin: 8px 0 4px 0;
                text-align: center;
            }

            .vcard {
                width: 280px;
                height: 300px;
                text-align: center;
                padding: 15px 10px 10px 10px;
                border: 2px solid rgba(0, 0, 0, 0.2);

                p {
                    line-height: 1.6;
                    word-spacing: 1.5px;
                    text-align: center;
                }
            }

            // 23-09-23 : changes
            .vcard:nth-child(1) {
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                padding-bottom: 40px !important;
            }
            
            // 23-09-23 : changes
            .vcard:nth-child(2) {
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                padding-bottom: 30px !important;
            }

        }
    }

}

@media only screen and (max-width: 600px) {
    .investWrapper {
        width: 80% !important;
        padding: 0 !important;
        margin-bottom: 50px !important;
    }

    .investWrapper h4, .investWrapper p {
        padding: 0 6px !important;
    }

    .investWrapper .vdeck {
        flex-wrap: wrap;
    }

    .investWrapper .vdeck .vcard {
        width: 100% !important;
    }

    .investWrapper .vdeck .vcard:nth-child(1), .investWrapper .vdeck .vcard:nth-child(2) {
        margin-bottom: 30px !important;
    }
}

@media only screen and (max-width: 600px) {
    .contactWrapper {
        width: 80% !important;
        flex-direction: column;
        margin-bottom: 60px !important;
    }
    .contactWrapper .info {
        width: 100% !important;
    }
    .contactWrapper .map {
        margin-top: 40px;
        width: 100% !important;
    }
}